.noData {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > img {
    margin-bottom: 24px;
  }

  > span {
    color: #8b90a0;
    font-size: 14px;
    line-height: 24px;
  }
}
