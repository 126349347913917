@import "../../styles/variables";

.loadingProgress {
  display: flex;
  width: 100%;
  padding-top: 245px;
  padding-bottom: 327px;
  color: #505565;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;

  circle {
    color: $color-primary;
  }
}
