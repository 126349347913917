@import "../../styles/variables";

.groupCard {
  display: flex;
  padding: 16px;
  border: 1px solid #e4e7ec;
  border-radius: $border-radius;

  &__item {
    display: flex;
    padding: 24px 29px 32px;
    flex: 1;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px dashed #d5d7de;
    }
  }

  &__title {
    margin-bottom: 16px;
    color: #454b5c;
    word-break: keep-all;
  }

  &__value {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }
}
