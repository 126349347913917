@import "../../../styles/variables";

.xAxisLabels {
  cursor: pointer;
}

.yAxisTitle {
  display: flex;
  margin-left: 18px;
  color: $color-gray-1;
  font-size: 14px;
  line-height: 24px;
  align-items: center;

  > img {
    cursor: pointer;
  }
}
