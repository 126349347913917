@import "../../styles/variables";

.footer {
  display: flex;
  height: 88px;
  color: $color-gray-1;
  font-size: 12px;
  justify-content: center;
  align-items: center;
}
