@import "../../styles/variables";

.errorQuestionsList {
  display: flex;
  flex-direction: column;

  &__description {
    margin-bottom: 12px;
    color: $color-gray-1;
    font-size: 14px;
    line-height: 24px;
  }
}

.list {
  display: flex;
  flex-direction: column;
}

.question {
  padding: 20px 23px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &__header {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px dashed #e4e7ec;
    align-items: center;
  }

  &__index {
    display: flex;
    background-color: #121232;
    margin-right: 16px;
    width: 32px;
    height: 32px;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }

  &__group {
    margin-right: 12px;
    font-weight: 500;
  }

  &__knowledge {
    margin-right: 12px;
    color: $color-gray-1;
    font-size: 14px;
  }

  &__difficulty {
    display: flex;
    padding: 3px 8px;
    font-size: 12px;
    line-height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &--BEGIN {
      background-color: rgb(238, 247, 239);
      color: rgb(76, 175, 80);
    }

    &--INTERMEDIATE {
      background-color: rgb(255, 246, 232);
      color: rgb(255, 152, 0);
    }

    &--EXPERT {
      background-color: rgb(255, 237, 236);
      color: rgb(244, 67, 54);
    }
  }

  &__errorRate {
    background-color: rgb(255, 237, 236);
    margin-left: auto;
    padding: 4px 16px;
    color: rgb(244, 67, 54);
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 24px;
  }

  &__content {
    margin-top: 16px;

    > img {
      width: 100%;
    }
  }
}

.button {
  display: flex;
  width: 112px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  align-self: center;

  > img {
    margin-right: 8px;
  }
}
