@import "../../styles/variables";

.block {
  display: flex;
  padding: 40px;
  flex-direction: column;
  border-radius: $border-radius;

  &__title {
    display: flex;
    margin-bottom: 24px;
    font-size: 20px;
    align-items: center;
  }

  &__block {
    background-color: #f9c74f;
    margin-right: 16px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
  }

  &__toolbar {
    margin-left: auto;
  }
}
