@import "../../../styles/variables";

.search {
  margin-bottom: 26px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e7ec;
}

.header {
  display: flex;
  margin-top: 24px;
  margin-bottom: 20px;
  color: $color-black-2;
  font-size: 14px;
  line-height: 24px;
  align-items: center;

  &__checkbox {
    padding: 0;
    margin: 0 8px;

    > svg {
      fill: $color-black-2;
    }
  }
}

.table {
  &__cell {
    &--disabled {
      color: #a1a4b1;
    }
  }

  &__examName {
    display: flex;
    max-width: 100%;

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
    }

    &__newTag {
      background: #fef2f0;
      display: flex;
      width: 40px;
      margin-left: 10px;
      color: $color-primary;
      font-size: 12px;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
    }
  }
}
