@import "../../../styles/variables";

.seatNo {
  display: flex;
  width: 76px;
  justify-content: center;
}

.name {
  flex: 1;
}

.avgRate {
  display: flex;
  width: 160px;
  align-items: center;

  > img {
    margin-left: 6px;
    cursor: pointer;
  }
}

.action {
  padding: 0 16px !important;
  width: 120px;
}

.noRecord {
  color: #a1a4b1;
}

.table {
  line-height: 24px;

  &__row {
    &--head {
      background-color: #fafafc;
      display: flex;
      font-size: 14px;
      border-bottom: 1px solid $border-color;

      > * {
        padding: 17px 24px;
      }
    }

    &--body {
      display: flex;
      height: 56px;
      border-bottom: 1px solid $border-color;

      > * {
        display: flex;
        padding: 0 24px;
        align-items: center;
      }

      & .seatNo {
        display: block;
        margin: auto;
        padding: 0 12px;
        color: $color-gray-1;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  &__body {
  }

  &__pagination {
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
  }
}
