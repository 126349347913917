.customTooltip {
  background-color: #fff;
  width: 160px;
  padding: 12px;
  border-radius: 12px;

  &__title {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
    white-space: pre-line;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__item {
    display: flex;
    font-size: 12px;
    align-items: center;
  }

  &__type {
    flex: 1;
  }

  &__value {
    font-weight: 700;
  }

  &__dot {
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &__darkGreen {
    background-color: #4c99a5;
  }

  &__lightGreen {
    background-color: #a5cbd1;
  }

  &__orange {
    background-color: #ff9800;
  }

  &__blue {
    background-color: #0094ff;
  }
}
