@import "./variables";
@import "./customTooltip";

.highcharts-label .customTooltip {
  border-radius: 12px !important;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1) !important;
}

.highcharts-tooltip-box {
  stroke-width: 0;
}

.highChartXAxisLabel {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    display: flex;
    width: 56px;
    font-size: 14px;
    font-family: none;
    line-height: 24px;
    flex-direction: column;
    align-items: center;
  }

  &__date {
    color: $color-gray-1;
  }

  &__name {
    height: 48px;
    color: #454b5c;
    text-align: center;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__noRecord {
    display: flex;
    width: 36px;
    height: 35px;
    color: #a1a4b1;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    white-space: normal;
    align-items: center;
  }
}
