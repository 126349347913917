@import "../../styles/variables";

.header {
  display: flex;
  justify-content: center;
  font-size: 0;

  &__wrapper {
    width: $max-width;
  }

  &__logo {
    margin: 17px 0 17px 70px;
    height: 30px;
  }
}
