@import "./variables";

* {
  color: $color-black-1;
  line-height: 1.5;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}

html,
body {
  background-color: #f7f7fa;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

a:link,
a:visited {
  text-decoration: none;
}

.container {
  max-width: 1200px;
}

.table th,
.table td {
  padding: 13px;
  vertical-align: middle;
  border: 0px;
}
