@import "../../styles/variables";

.banner {
  display: flex;
  padding: 0 20px;
  align-items: center;

  &__title {
    margin-left: 20px;
    margin-right: 16px;
    font-size: 24px;
    font-weight: 500;
  }

  &__class {
    color: $color-gray-1;
    font-size: 14px;
  }
}

.container {
  background-color: #fff;
  padding: 32px 40px 40px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(145, 158, 171, 0.08);
}

.search {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e7ec;
}

.table {
  &__toolbar {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
    align-items: center;
  }

  &__eduSubject {
    font-size: 18px;
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 12px;

    &__button {
      padding: 8px 16px;
      line-height: 24px;

      > img {
        margin-right: 8px;
      }
    }

    &__divider {
      margin: 0 4px;
      height: 32px;
      border: 1px solid $border-color;
    }
  }

  &__cell {
    max-width: 80px;
    word-break: break-all;
  }

  &__examName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__date {
      color: $color-gray-1;
    }

    &__name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: left;
    }
  }

  &__notAnswered {
    color: #a1a4b1;
  }
}

.noExams {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px 0;

  > img {
    margin-bottom: 8px;
  }

  > span {
    color: $color-gray-1;
  }
}
