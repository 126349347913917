@import "../../styles/variables";

.testTime {
  width: 175px;
}

.name {
  flex: 1;
}

.scoreRate,
.pr {
  display: flex;
  width: 160px;
  align-items: center;

  > img {
    margin-left: 6px;
    cursor: pointer;
  }
}

.spendTime {
  width: 160px;
}

.action {
  padding: 0 16px !important;
  width: 120px;
}

.noRecord {
  color: #a1a4b1;
}

.table {
  line-height: 24px;

  &__row {
    &--head {
      background-color: #fafafc;
      display: flex;
      font-size: 14px;
      border-bottom: 1px solid $border-color;

      > * {
        padding: 17px 16px;
      }
    }

    &--body {
      display: flex;
      height: 56px;
      border-bottom: 1px solid $border-color;

      > * {
        display: flex;
        padding: 0 16px;
        align-items: center;
      }
    }
  }

  &__body {
  }

  &__pagination {
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
  }
}
