@import "../../../styles/variables";

.yAxisTitleGroup {
  display: flex;
  justify-content: space-between;
}

.yAxisTitle {
  display: flex;
  margin-left: 18px;
  color: $color-gray-1;
  font-size: 14px;
  line-height: 24px;
  align-items: center;

  > img {
    margin-left: 6px;
    cursor: pointer;
  }
}

.legend {
  margin-top: 20px;
  display: flex;
  color: $color-black-2;
  font-size: 14px;
  justify-content: center;

  &__item {
    display: flex;
    margin-right: 24px;
    align-items: center;
  }

  &__icon {
    &--bar {
      width: 16px;
      height: 16px;
    }

    &--line {
      position: relative;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;

      &::after {
        content: "";
        background-color: inherit;
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 2px;
        transform: translate(-5px, -1px);
      }
    }
  }

  &__label {
    margin-left: 8px;
    color: $color-black-2;
  }
}
