@import "../../styles/variables";

.container {
  display: flex;
  flex-wrap: wrap;

  > * {
    background-color: #fff;
    width: 100%;
    border-radius: $border-radius;
  }
}

.about {
  display: flex;
  padding: 48px 64px 48px;
  flex-direction: column;

  > :first-child {
    display: flex;

    &:has(+ *) {
      margin-bottom: 32px;
    }

    > :first-child {
      flex: 1;
    }
  }

  &__title {
    margin-bottom: 24px;
    color: $color-black-2;
    font-size: 20px;

    &__type {
      color: $color-primary;
    }
  }

  &__examName {
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
  }

  &__classInfo {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > :first-child {
      margin-right: 24px;
      color: $color-gray-1;
    }
  }

  &__score {
    display: flex;
    margin-top: 65.5px;
    margin-right: 32px;
    flex-direction: column;

    &--notPublished {
      display: flex;
      margin-right: 72px;
      align-items: center;

      > span {
        color: #d5d7de;
        font-size: 24px;
      }
    }

    &__title {
      display: flex;
      margin-left: 64px;
      color: #454b5c;
      align-items: center;
    }

    &__content {
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-right: 24px;
      width: 40px;
      height: 40px;
    }

    &__point {
      display: flex;
      font-size: 20px;
      align-items: flex-end;
      line-height: 60px;

      &__numerator {
        margin-right: 16px;
        font-size: 64px;
        font-weight: 600;
        line-height: 87px;
      }

      &__denominator {
        margin-left: 16px;
        color: $color-gray-1;
        line-height: 60px;
      }
    }
  }
}

.notPublished {
  display: flex;
  padding: 56px 0;
  flex-direction: column;
  align-items: center;

  > img {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 500;
  }
}
