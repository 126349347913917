@import "../../styles/variables";

.search {
  padding: 40px;
  border-radius: $border-radius;

  &__title {
    display: flex;
    margin-bottom: 32px;
    color: $color-black-2;
    font-size: 20px;

    &__type {
      color: $color-primary;
    }

    &__name {
      display: flex;
    }
  }

  &__inputs {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.divider {
  background-color: rgba(145, 158, 171, 0.24);
  width: 1px;
  height: 40px;
}
