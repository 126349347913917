@import "../../../styles/variables";

.search {
  margin: 24px 0 32px;
}

.table {
  &__cell {
    &--disabled {
      color: #a1a4b1;
    }
  }

  &__examName {
    display: flex;
    max-width: 100%;

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
    }

    &__newTag {
      background: #fef2f0;
      display: flex;
      width: 40px;
      margin-left: 10px;
      color: $color-primary;
      font-size: 12px;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
    }
  }
}

.selfLearningBanner {
  background-image: url("../../../../public/assets/self-learning-banner.svg");
  min-height: 126px;
  display: flex;
  align-items: center;
  margin: 32px 0 40px;
  padding: 25px 0px;
}

.errorQuestionWrapper {
  display: flex;
  align-items: center;
  margin-left: 31px;

  &__title {
    margin-left: 34px;
  }

  &__number {
    font-weight: 500;
    font-size: 24px;
    color: #242c3f;
    line-height: 36px;
  }

  &__slogan {
    font-size: 16px;
    color: #242c3f;
    line-height: 24px;
  }
}
.goToSelfLearningButton {
  width: 184px;
  height: 48px;
  background: #121232;
  border-radius: 24px;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: 60px;
}
.myErrorQuestion {
  width: 100px;
  height: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #242c3f;
}
