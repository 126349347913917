@import "../../../styles/variables";

.yAxisTitle {
  display: flex;
  margin-left: 10px;
  margin-bottom: 8px;
  color: $color-gray-1;
  font-size: 14px;
  line-height: 24px;
  align-items: center;

  > img {
    cursor: pointer;
  }
}
