@import "../../../styles/variables";

.search {
  margin-bottom: 26px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e7ec;
}

.header {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  &__title {
    max-width: 817px;
    color: #454b5c;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__description {
    color: $color-gray-1;
    font-size: 14px;
    line-height: 24px;
  }

  &__right {
    display: flex;
    margin-top: 7px;
    align-items: center;
    column-gap: 16px;

    &__toggle {
      display: flex;
      padding: 4px;
      column-gap: 4px;
      border: 1px solid rgba(145, 158, 171, 0.12);
      border-radius: 8px;

      > * {
        padding: 8px;
        color: #637381;
        font-size: 14px;
        line-height: 26px;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;

        &.active {
          background-color: rgba(236, 121, 99, 0.08);
          color: $color-primary;
        }
      }
    }
  }
}

.selectedStudents {
  background-color: #faf4d5;
  display: flex;
  padding: 16px 0;
  font-size: 14px;
  line-height: 24px;
  justify-content: center;
  align-items: center;
}

.dataGrid {
  &__seatNo {
    color: $color-gray-1 !important;
  }
}

.snackbar {
  display: flex;
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
  align-items: center;

  &__icon {
    background: rgba(84, 214, 44, 0.16);
    margin-right: 12px;
    padding: 10px;
    font-size: 0;
    border-radius: 12px;
  }

  > span {
    min-width: 192px;
  }
}
