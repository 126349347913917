@import "../../styles/variables";

.reportGenerating {
  display: flex;
  padding: 56px 0;
  flex-direction: column;
  align-items: center;

  &__progressBar {
    background-color: #d5d7de;
    margin-top: 24px;
    margin-bottom: 16px;
    width: 24px;
    height: 4px;
    border-radius: 2px;
    animation: loading 2s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
  }

  &__title {
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 500;
  }
}

@keyframes loading {
  0%,
  100% {
    transform: translate(-44px, 0);
  }

  50% {
    transform: translate(44px, 0);
    width: 40px;
  }
}
