@import "../../styles/variables";

.container {
  display: flex;
  flex-wrap: wrap;

  > * {
    background-color: #fff;
    width: 100%;
    border-radius: $border-radius;
  }
}

.about {
  display: flex;
  padding: 48px 64px 72px;
  flex-direction: column;

  > :first-child {
    display: flex;
    margin-bottom: 32px;

    > :first-child {
      flex: 1;
    }
  }

  &__eduSubject {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
  }

  &__classInfo {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > :first-child {
      margin-right: 24px;
      color: $color-gray-1;
    }
  }

  &__scoreRate {
    display: flex;
    flex-direction: column;

    &__title {
      display: flex;
      margin-left: 64px;
      color: #454b5c;
      align-items: center;

      > :first-child {
        margin-right: 6px;
      }
      > :last-child {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    &__content {
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-right: 24px;
      width: 40px;
      height: 40px;
    }

    &__percentage {
      display: flex;
      font-size: 20px;
      align-items: flex-end;
      line-height: 60px;

      > span {
        margin-right: 16px;
        font-size: 64px;
        font-weight: 600;
        line-height: 87px;
      }
    }
  }
}

.distribution {
  display: flex;
}

.charts {
  max-width: 902px;
  margin-right: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.distributionExample {
  display: flex;
  width: 186px;
  height: 256px;
  border: 1px solid #e4e7ec;
  border-radius: $border-radius;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    display: flex;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-between;

    &__pr {
      display: flex;
      align-items: center;

      > span {
        margin-right: 6px;
      }

      > *:last-child {
        cursor: pointer;
      }
    }
  }

  > img {
    padding: 0 16px;
    width: 100%;
  }
}
