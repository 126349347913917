.snackbar {
  display: flex;
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16);
  align-items: center;
  font-size: 14px;
  line-height: 22px;

  &__icon {
    margin-right: 12px;
    border-radius: 12px;
  }

  &__message {
    min-width: 192px;
  }
}
