@import "../../../styles/variables";

.dialog {
  padding: 40px 48px;

  &__questions {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .question {
      padding: 32px 24px;
      border: 1px solid #e4e7ec;
      border-radius: 8px;

      &__chip {
        background: #eef5f6;
        display: inline-block;
        margin-bottom: 16px;
        padding: 0 16px;
        color: #4c99a5;
        font-size: 12px;
        line-height: 24px;
        border-radius: 16px;

        &:not(:first-child) {
          margin-top: 32px;
        }
      }

      &__content {
        > img {
          width: 100%;
        }
      }

      &__divider {
        background-color: #e4e7ec;
        margin: 24px 0 16px;
        width: 100%;
        height: 1px;
      }

      &__type {
        margin-bottom: 16px;
        color: #454b5c;
      }

      &__answer {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &__label {
          &--student,
          &--correct {
            margin-right: 20px;
            font-size: 14px;
            line-height: 24px;
          }

          &--student {
            color: #121232;
          }

          &--correct {
            color: #0094ff;
          }
        }

        .answer__options {
          display: flex;
          column-gap: 8px;

          > * {
            width: 32px;
            height: 32px;
            display: flex;
            color: #d5d7de;
            justify-content: center;
            align-items: center;
            border: 1px solid #e4e7ec;
            border-radius: 4px;
          }

          &__student {
            background-color: #121232;
            color: #fff;
          }

          &__correct {
            background-color: #0094ff;
            color: #fff;
          }
        }
      }
    }
  }
}
