@import "../../styles/variables";

.errorRateTable {
  display: flex;
  flex-direction: column;

  &__description {
    margin-bottom: 12px;
    color: $color-gray-1;
    font-size: 14px;
    line-height: 24px;
  }
}

.table {
  font-size: 14px;

  &__type {
    flex: 1;
  }

  &__rate {
  }

  &__row {
    &--head {
      display: flex;
      background-color: #fafafc;
      padding: 9px 16px;
      border-bottom: 1px solid $border-color;
    }

    &--body {
      display: flex;
      padding: 16px;
      line-height: 23px;
      border-bottom: 1px solid $border-color;
    }
  }

  &__body {
    max-height: 280px;
    overflow-y: scroll;
  }
}

.noData {
  display: flex;
  padding: 36px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    margin-top: 24px;
    color: $color-gray-1;
    font-size: 14px;
    line-height: 24px;
  }
}
