@import "../../styles/variables";

.banner {
  display: flex;
  padding: 0 20px;
  align-items: center;

  &__title {
    margin-left: 20px;
    margin-right: 16px;
    font-size: 24px;
    font-weight: 500;
  }

  &__class {
    color: $color-gray-1;
    font-size: 14px;
  }
}

.container {
  background-color: #fff;
  padding: 8px 40px 48px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(145, 158, 171, 0.08);
}

.tabs {
  margin: 0 -40px 24px;
  padding-left: 24px;
  border-bottom: 1px solid $border-color;
}
