@import "../../../styles/variables";

.container {
  display: flex;
}

.chart {
  flex: 1;
}

.xAxisLabel {
  display: flex;
  width: 100px;
  margin-top: auto;
  margin-bottom: 50px;
  color: $color-gray-1;
  font-size: 14px;
  align-items: center;

  > img {
    cursor: pointer;
  }
}
