.getBackIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #8b90a0;
  font-size: 14px;
}

.container {
  background-color: #fff;
  padding: 48px 40px 20px 48px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(145, 158, 171, 0.08);
}

.wrapper {
  padding-bottom: 32px;
  border-bottom: 1px solid #e4e7ec;
  margin-bottom: 32px;
}

.header {
  margin: 22px 0px 24px 50px;

  &__title {
    font-size: 24px;
  }

  &__info {
    font-size: 16px;
    color: #8b90a0;
  }
}

.step {
  &__title {
    font-size: 20px;
  }

  &__info {
    color: #8b90a0;
    font-size: 16px;
    margin-top: 20px;
  }

  &__buttonGroup {
    display: flex;
    align-items: center;
    column-gap: 12px;
    flex-wrap: wrap;
  }
}

.emptyData {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e4e7ec;
  padding: 40px;

  &__info {
    color: #8b90a0;
    font-size: 16px;
    margin-top: 24px;
  }
}

.noChooseSubjectWarning {
  font-weight: 500;
  font-size: 16px;
  color: #8b90a0;
  margin-top: 16px;
}

.chosenNumber {
  color: #4c99a5;
  font-size: 16px;
  margin: 16px 0px;
}

.footerButtonWrapper {
  display: flex;
  justify-content: center;
}
